import React from "react"
import SEO from "../components/seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-image-lightbox/style.css"
import { Banner } from "../components/main-page/Banner"
import { Layout } from "../components/parts/Layout"
import { ProjectsGrid } from "../components/projects/ProjectsGrid"
import { ContactUs } from "../components/main-page/ContactUs"

const Projects = () => (
  <Layout>
    <SEO title="Проекты"/>
    <Banner/>
    <ProjectsGrid/>
    <ContactUs/>
  </Layout>
)

export default Projects
