import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "gatsby"
import "./ProjectsGrid.scss"

function ProjectsGrid() {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    (async () => {
      const url = process.env.REACT_URL
      await axios({
        method: "GET",
        url: `${url}/gallery/buildings`,
      }).then(res => {
        if (!res) return
        setProjects(res.data)
      })
    })()

  }, [])

  return (
    <section className="ProjectsGrid">
      <div className="container">
        <div className="ProjectsGrid-wrapper">
          {
            projects.map((project, index) => (
              <Link
                key={project.id}
                className="ProjectsGrid-box"
                to={`project?id=${project.id}`}
              >
                <div className="ProjectsGrid-layer-first"/>
                <div className="ProjectsGrid-layer-second"/>
                <div className="ProjectsGrid-box-wrapper">
                  <div className="ProjectsGrid-bg"
                       style={{ backgroundImage: `url('${project.poster}` }}/>
                  <div className="ProjectsGrid-wrap">
                    <h3 className="ProjectGrid-building">
                      {project.name}
                    </h3>
                  </div>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default ProjectsGrid
