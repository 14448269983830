import React from "react"
import Slider from "react-slick"
import home1 from "../../../images/banner/101_home.png"
import home2 from "../../../images/banner/102_home.png"
import home3 from "../../../images/banner/103_home.png"
import home4 from "../../../images/banner/104_home.png"
import home5 from "../../../images/banner/105_home.png"
import home6 from "../../../images/banner/106_home.png"
import home7 from "../../../images/banner/107_home.png"
import home8 from "../../../images/banner/108_home.png"
import home9 from "../../../images/banner/109_home.png"
import home10 from "../../../images/banner/Bedroom_music_View090000_-min.png"
import home11 from "../../../images/banner/home_banner_001-min.jpg"
import { CustomCarouselArrow } from "../../CustomCarouselArrow"

import "./Banner.scss"

const slides = [
  home11,
  home1,
  home2,
  home3,
  home4,
  home5,
  home6,
  home7,
  home8,
  home9,
  home10,
]

function Banner() {


  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6500,
    slidesToShow: 1,
    fade: true,
    lazyLoad: true,
    prevArrow: <CustomCarouselArrow position="left"/>,
    nextArrow: <CustomCarouselArrow/>,
  }

  return (
    <section className="Banner">
      <Slider {...settings}>
        {
          slides.map((item, index) => (
            <div key={index} className="Banner-slide">
              <div className="Banner-content">
                <h1 className="title">
                  Дизайн интерьера
                </h1>
                <p className="desc">
                  Каждое помещение мы рассматриваем сквозь призму <br/> Ваших особенностей задач, Вашего стиля
                  жизни.
                </p>
                <a href="#works" className="btn outline-white">
                  Наши работы
                </a>
              </div>
              <div className="Banner-image" style={{ backgroundImage: `url(${item})` }}/>
            </div>
          ))
        }
      </Slider>
    </section>
  )

}

export default Banner
